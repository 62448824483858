import { useStore } from '@nanostores/react';
import { useEffect } from 'react';
import { $embedVideoModalOptions, closeEmbedVideoModal } from '../../stores/embedVideoModalStore';
import EmbedVideo from './EmbedVideo';
import * as styles from './EmbedVideoModalOverlay.module.scss';
import ModalOverlay from './ModalOverlay';

export default function EmbedVideoModalOverlay() {
  const embedVideoModalOptions = useStore($embedVideoModalOptions);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      if (embedVideoModalOptions !== null) {
        event.preventDefault();
        closeEmbedVideoModal();
      }
    };

    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [embedVideoModalOptions]);

  return (
    <ModalOverlay
      open={!!embedVideoModalOptions}
      onClose={() => closeEmbedVideoModal()}
      modalClassName={styles.modal}
    >
      {embedVideoModalOptions && <EmbedVideo url={embedVideoModalOptions.embedVideoUrl} />}
    </ModalOverlay>
  );
}
